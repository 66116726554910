body{
   
}
/* .modal{
    height: 350px!important;
} */

.menuspace{
    height: 120px;

}
.pac-container{
    z-index: 999999!important;
}
.map-container{
    height: 200px;
    width:350px;
 
}

.places-container{
    width:450px
}
.fixedaccordian{
    max-height: 270px!important;
    /* max-width: 1300px; */
    max-width: 100%!important;
    overflow-x: hidden!important;
    /* z-index: -999; */
}
footer {
    position: fixed;
    /* z-index: -999; */
    bottom: 0!important;
    height: 60px;
    width: 100%;
    max-width: 100%;
    background-color: white!important;
    font-size: 12px;
}
.topnew{
    /* margin-top: 5px; */
    /* padding-left: 50px !important; */
    /* max-height: 450px; */
}
.margleft{
    margin-left: -50px!important;
    width: 240px!important;
}
@media only screen and (max-width: 640px) {
    /* .menuspace{
        margin-left: -450px !important;
       
        
        
    } */
    
    .topnew{
        /* margin-top: 5px; */
        padding-left: 0px !important;
        max-height:0px;
    }
    footer {
        position: fixed;
        /* z-index: -999; */
        bottom: 0;
        height: 60px;
        width: 100%;
        max-width: 100%;
        background-color: white!important;
        font-size: 12px;
    }


}
@media only screen and (max-width: 640px) {
    .topnew{
        /* margin-top: 5px; */
        padding-left: 0px !important;
        max-height: 450px;
    }
}